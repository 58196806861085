import { BaseModel } from './baseModel';
import { ComissionadoModel } from './comissionadoModel';

export class CampanhaModel extends BaseModel {
  nmCampanha: string;
  dtInicioCampanha: string;
  cdPublicoAlvo: number;            // 1 - guia/agencia; 2 - cliente
  comissionado: ComissionadoModel;
  pcTaxaComissao: number;
  dtEncerramentoComissao: string;
  dlUrlDestino: string;
  flVigente: number;

  dtInicio_DD_MM_YYYY?: string;     // não existe no BD
  // flSelecao: boolean;               // não existe no BD
  dsPublicoAlvo?: string;            // não existe no BD
  nmGrupoEstatistica: string;        // não existe no BD

  constructor() {
      super();
      this.comissionado = new ComissionadoModel();
  }

}
